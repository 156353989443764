.kv {
    width: 100%;
    height: 100vh;
    max-height: 1080px;
    @include respond('laptop-xl') {
        padding: 0 16px;
    }
    .bg {
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.4);
        }
        img {
            position: absolute;
        }
        video {
            position: absolute;
        }
        
    }
    .st0 {
      enable-background: new;
    }

    .st1 {
      fill: none;
      stroke: #F0F0F0;
      stroke-width: 2;
      stroke-linejoin: round;
    }

    .center {
        width: 100%;
        max-width: 1672px;
        @include centered;
        @include respond('laptop') {
            max-width: 1000px;
        }
    }
    svg {
        left: 50px;
        position: relative;
        opacity: .6;
        @include respond('laptop-xl') {
            left: auto;
        }
        @include respond('tab-port') {
            padding: 0 16px;
            width: 100%;
        }
    }
    h1 {
        img {
            max-width: 394px;
            left: 26px;
            position: relative;
            z-index: 1;
            @include respond('laptop') {
                max-width: 300px;
                height: auto;
                left: auto;
            }
            @include respond('tab-port') {
                max-width: 240px;
            }
            @include respond('phone') {
                max-width: 120px;
            }
        }
    }
}