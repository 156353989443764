.grid {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);

  &.col-2 {
    grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
  }

  &.col-2-1 {
    grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-areas:
      "i1 i1"
      "i2 i3";
    justify-content: center;

    @include respond('laptop-xs') {
      rid-template-columns: 1fr;
      grid-template-areas:
      "i2"
      "i1"
      "i3";
    }

    @for $i from 1 through 3 {
      .grid__item[data-grid=#{"'i"+ $i + "'"}] {
        grid-area: unquote("i"+ $i);
      }
    }
  }

  &.col-3 {
    grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    gap: 14px;

    @include respond('laptop-xs') {
      grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
      justify-content: center;
      gap: 8px;
    }

    @include respond('phone') {
      grid-template-columns: 1fr;
      gap: 0px;
    }
  }

  &.col-6 {
    grid-template-columns: repeat(6, 320px);
    grid-template-rows: 320px;
    box-sizing: border-box;
    gap: 1px;
    box-sizing: border-box;

    @include respond('laptop-xs') {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(min-content, 380px);
    }

    @include respond('tab-port') {
      display: block;
    }

    .block:not(.has-content) {
      @include respond('laptop-xs') {
        display: none;
      }
    }
  }
}