.bg {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    video {
        width: 100%;
        min-width: 100vw;
        height: 100%;
        object-fit: cover;
    }
    @supports (display:-ms-grid) {
        video {
            width: 100%;
            min-width: 100vw;
            height: auto;
            object-fit: cover;
        }
     }


}