.about {
    width: 1920px;
    left: 50%;
    transform: translateX(-50%);
    .block {
        width: 320px;
        height: 320px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        margin-left: -1px;
        margin-top: -1px;
        border-bottom: none;
        border-right: none;
        position: relative;
        @include respond('laptop-xs') {
            width: 100%;
            height: 100%;
        }
        @include respond('tab-port') {
            width: 100%;
            height: 310px;
        }
    }
    .title {
        margin-top: 14px;
        margin-bottom: 6px;
    }
    .overlay {
        background-color: rgb(246, 243, 235);
        opacity: 0.1;
    }
    .card {
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        flex-direction: column;
        line-height: 1.33;
        padding-top: 8px;
        @include hover-supported {
            .hover {
                opacity: 0;
            }
            .frame img {
                transform: scale(1);
                transition: .3s ease-in-out;
            }
            .text {
                opacity: 1;
            }
        }
    }
    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    input:checked {
        ~ .card {
            .hover {
                opacity: 0;
            }
            .frame img {
                transform: scale(1);
                transition: .3s ease-in-out;
            }
            .text {
                opacity: 1;
            }
        }
    }      
    .arrow {
        width: 24px;
    }
    .frame {
        width: 290px;
        @include centered;
        @include respond('phone') {
            width: 270px;
        }
        img {
            transform: scale(0);
        }
    }
    .text {
        width: 268px;
        height: 100%;
        text-align: center;
        opacity: 0;
        @include centered;
    }
}