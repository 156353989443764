footer {
    // height: 48px;
    background-color: #141b21;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    padding: 12px 16px;
    letter-spacing: 0.7px;
    color: #e3dbca;
}