.nav {
    position: fixed;
    // top: 16px;
    padding-top: 16px;
    z-index: 99;
    right: 0;
    height: 100%;
    max-height: 1080px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    &.active {
        pointer-events: auto;
    }
    @include respond('laptop-xs') {
        width: 100%;
        max-height: 1366px;
        background: rgba(0, 0, 0, 0);
        pointer-events: none;
    }



    .logo {
        padding-right: 16px;
        @include respond('laptop-xs') {
            display: none;
        }
    }
    hr {
        width: 28px;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
        border: 0;
        margin-left: 4px;
    }
    ul {
        margin: 0;
        position: relative;
        // bottom: 40px;
        margin-bottom: 100px;
        @include respond('laptop-xs') {
            margin-bottom: 0;
        }
        li {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include hover-supported {            
                a {
                    transform: none;
                    transition: .2s ease-in-out;
                    background-color: rgba(0, 0, 0, 0.6);
                }
                span:first-child{
                    display: none;
                }
                span:nth-child(2){
                    display: flex;
                }
            }
            @include respond('laptop-xs') {
                transform: translateY(-60px) scale(0);
            }
            span:nth-child(2){
                display: none;
            }


        }
        a {
            color: rgba(255, 255, 255, 0.5);
            padding: 14px 0;
            display: flex;
            padding: 14px 0 11px 16px;
            transform: translateX(20px);
            &.active {
                color: #ffffff;
                hr {
                    background: #ffffff;
                }
            }
        }
        @include hover-supported {            
            a {
                color: #ffffff;
            }
            hr {
                background: #ffffff;
            }
        }
        .active {
            a {
                color: #ffffff;
            }
            hr {
                background: #ffffff;
            }
        }
    }
    @include respond('big') {
        right: calc(50% - 1920px / 2);
    }
    .socail {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 16px;
        a {
            @include respond('laptop-xs') {
                transform: translateY(-60px) scale(0);
            }
            &:hover {
                img {
                    opacity: 1;
                }
            }
        }
        img {
            transition: .3s ease-in-out;
            width: 100%;
            padding: 16px;
            opacity: .5;
        }
    }
}





/* hang burger */

.hamburger {
    width: 34px;
    height: 30px;
    margin: 40px auto;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 16px;
    background-color: transparent;
    display: none;
    pointer-events: auto;
    @include respond('laptop-xs') {
        display: block;
    }
  }
  

  
  .hamburger span {
    z-index: 3;
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: rgb(255, 255, 255);
    border-radius: 1px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .20s ease-in-out;
    -moz-transition: .20s ease-in-out;
    -o-transition: .20s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  .hamburger span:nth-child(1) {
    top: 0px;
  }
  
  .hamburger span:nth-child(2) {
    top: 12px;
  }
  
  .hamburger span:nth-child(3) {
    top: 24px;
  }
  
  .hamburger.open span:nth-child(1) {
    top: 14px;
    transform: rotate(135deg);
    background-color: #fff;
  }
  
  .hamburger.open span:nth-child(2) {
    opacity: 0;
    left: -30px;
    transition: .16s ease-in-out;
  }
  
  .hamburger.open span:nth-child(3) {
    top: 14px;
    transform: rotate(-135deg);
    background-color: #fff;
  }