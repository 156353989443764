/* MEDIA QUERIES */
@mixin respond($breakpoint) {
  @if $breakpoint==iphone5s {
      @media only screen and (max-width: 350px) {
          @content
      }

      ;
  }

  @if $breakpoint==phone {
      @media only screen and (max-width: 480px) {
          @content
      }

      ;
  }

  @if $breakpoint==tab-port {
      @media only screen and (max-width: 769px) {
          @content
      }

      ;
  }

  @if $breakpoint==tab-mid {
      @media only screen and (max-width: 991px) {
          @content
      }

      ;
  }

  @if $breakpoint==tab-land {
      @media only screen and (max-width: 1025px) {
          @content
      }

      ;
  }

  @if $breakpoint==laptop-xs {
      @media only screen and (max-width: 1281px) {
          @content
      }

      ;
  }

  @if $breakpoint==ipad-pro {
      @media only screen and (max-width: 1281px) {
          @content
      }

      ;
  }

  @if $breakpoint==laptop {
      @media only screen and (max-width: 1441px) {
          @content
      }

      ;
  }

  @if $breakpoint==laptop-xl {
      @media only screen and (max-width: 1700px) {
          @content
      }

      ;
  }

  @if $breakpoint==big {
      @media only screen and (min-width: 1921px) {
          @content
      }

      ;
  }
}
//! Example usage
/* @include respond-to('mobile') {} */


/* CENTERING */
@mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//! Example usage
/*
.parent {
position: relative;
}
.child {
@include centered;
}
*/

@mixin hover-supported {    
  @media not all and (pointer: coarse) {
      &:hover {
          @content;
      }
  }
}

// mixin to convert value
@mixin letter-spacing($photoshop-value) {
    letter-spacing: $photoshop-value/1000 + em;
}