*::selection {
  background: $pink;
}

html,
body {
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  background: #181b22;
}
main {
   max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
}
section {
  position: relative;
  overflow: hidden;
}
.z-1 {
  z-index: 1;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.relative {
  position: relative;
}
.overflow-hidden	{
  overflow: hidden;
}
.overflow-visible	{
  overflow: visible;
}
.text-center {
  text-align: center;
}
.image {
  width: 100%;
}
.wrapper {
  max-width: 100%;
}
.container {
 margin:  0 auto;
}
.absolute {
  position: absolute;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: $blue;
}

img {
  display: block;
}

ul {
  padding: 0;
  list-style: none;
}