.state {
    overflow: visible;
    padding-top: 19.6rem;
    padding-bottom: 13rem;
    .primary span{
        display: inline-block;
    }
    @include respond('phone') {
        padding-top: 10rem;
        padding-bottom: 8rem;
    }
    img {
        @include respond('tab-port') {
            width: 100%;
        }
    }
    .drone {
        position: absolute;
        z-index: 2;
        top: -350px;
        // right: -20%;
        left: -20%;
        width: auto;

        @include respond('laptop') {
            max-width: 700px;
            top: -200px;
        }
        @include respond('tab-port') {
            max-width: 500px;
            top: -100px;
        }
        @include respond('phone') {
            max-width: 300px;
        }
    }

    .container {
        width: 100%;
        max-width: 1020px;
        position: relative;
    }
    .frame {
        @include respond('tab-port') {
            visibility: hidden;
        }
    }
    .man {
        @include centered;
        @include respond('tab-port') {
            width: 100%;
            max-width: 514px;
        }
    }
    .count {
        position: absolute;
        bottom: -34px;
        left: 50%;
        transform: translateX(-50%);
        letter-spacing: 1.8px;
    }
    .grid__item {
        @include respond('tab-port') {
            padding: 0 16px;
        }
    }
    .grid__item:first-child {
        margin-bottom: 4rem;
        @include respond('tab-port') {
            margin-bottom: 2rem;
        }
    }
    .grid__item:nth-child(2) {
        max-width: 563px;
        margin: 0 auto 4rem;
    }
    .grid__item:not(:first-child) {
        right: 78px;
        position: relative;
        @include respond('laptop-xs') {
            position: static;
        }
    }
    .controler {
        margin-left: auto;
        left: 42px;
        position: relative;
        max-width: 214px;
        @include respond('tab-port') {
            left: auto;
        }
    }
    ul {
        padding-left: 52px;
        margin: 24px 0 0 0;
        @include respond('tab-port') {
            padding-left: 0;
        }
    }
}