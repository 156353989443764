.fade-enter-active {
    transition: opacity .5s ease-in-out;
  }
  .fade-leave-active {
    transition: opacity .5s ease-in-out;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    position: absolute;
  }