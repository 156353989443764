.contact {
    padding-top: 126px;
    padding-bottom: 174px;
    .fax {
        pointer-events: none;
    }
    @include respond('laptop-xs') {
        padding: 0;
    }
    .title {
        @include respond('phone') {
            font-size: 2.8rem;
        }
    }
    img {
        width: 100%;
    }
    .subtitle {
        margin-top: 16px;
        line-height: 1.67;
        letter-spacing: 0.9px;
        max-width: 470px;
    }
    .container {
        width: 1300px;
        max-width: 100%;
        height: 600px;
        box-shadow: 0px 17px 30.8px 9.2px rgba(0, 0, 0, 0.15);
        background-color: rgba(0, 0, 0, 0.7);
        padding: 100px 110px; 
        @include respond('laptop-xs') {
            width: 100%;
            height: auto;
            padding: 100px 16px; 
        }
    }
    .grid.col-2 {
        justify-content: center;
        @include respond('laptop-xs') {
            grid-template-columns: 1fr;
        }
        &>div:first-child {
            margin-right: 16px;
        }
    }
    li {
        display: flex;

        line-height: 2.22;
        letter-spacing: 0.9px;
        div:first-child {
            margin-right: 18px;
        }
        &:not(:last-child) {
            align-items: center;
        }
        .grid.col-2 {
            
            div {
                max-width: 424px;
            }
            @include respond('laptop-xs') {
                grid-template-columns: minmax(min-content,max-content) minmax(min-content,max-content);
            }
        }
    }
    .mt {
        min-width: 16px;
        margin-top: 6px;
        padding-left: 4px;
    }
}