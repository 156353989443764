.works {
    margin-top: -12.2rem;
    padding-top: 27rem;
    padding-bottom: 14.2rem;
    @include respond('laptop') {
        padding-top: 13rem;
        margin-top: 0;
    }
    @include respond('laptop-xs') {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .bg {
        max-height: 552px;
    }
    .title {
        letter-spacing: 2.4px;
        margin-bottom: 3rem;
        @include respond('laptop-xs') {
            margin-bottom: 2rem;
        }
    }
    .btn-more {
        width: 200px;
        height: 58px;
        border-radius: 10px;
        background-color: #4285bf;
        letter-spacing: 0.9px;
        margin: 0 auto;
        
        @include respond('laptop-xs') {
            width: 170px;
            height: 50px;
        }
        @include hover-supported {
            transition: .3s ease-in-out;
            background-color: #193f60;
        }
    }
    .container {
        width: 100%;
        max-width: 1228px;
        margin-bottom: 4.8rem;
        @include respond('laptop-xs') {
            padding: 0 16px;
            margin-bottom: 3.6rem;
        }
        @include respond('phone') {
            padding: 0;
            margin-bottom: 2.4rem;
        }
    }
    .overlay {
        background-color: rgba(44, 54, 78, 0.7);
        transition: .3s ease-in-out;
        transform: scale(0);
        img {
            transform: scale(0);
            transition: .3s ease-in-out .2s;
        }
        @include respond('laptop-xs') {
            opacity: 0;
        }
    }
    .wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-left: 16px;
        padding-bottom: 14px;
        letter-spacing: 0.7px;
        z-index: 1;
        
        transform: scale(0);
        @include respond('laptop-xs'){
            transform: scale(1);
            width: 100%;
            padding-top: 14px;
            background: linear-gradient(to top, rgba(44, 54, 78,.8) 22%,rgba(44, 54, 78, 0.7) 59%,rgba(125,185,232,0) 100%);
        }
    }
    .hover {
        @include hover-supported {
            .overlay {
                transform: none;
                img {
                    transform: scale(1);
                }
            }
            .wrap {
                transform: scale(1);
                transition: .3s ease-in-out .3s;
            }
        }
    }
}