//! import google fonts
// @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,900|Oleo+Script&display=swap');

// OR use @font-face like below

// //! import a custom font below
// @font-face {
//   font-family: 'Libre-Baskerville-Reg';
//   font-display: fallback;
//   font-style: normal;
//   src:
//     local('Libre Baskerville'), /* Checks if font is on users computer */
//     local('LibreBaskerville-Regular'), /* Checks if font is on users computer */
//     url('fonts/libre-baskerville-v5-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//     url('fonts/libre-baskerville-v5-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }

//! set font variables
$font-heading: 'Libre-Baskerville-Reg', Fallback, 'Georgia', serif;
$font-body: 'Nunito Sans', sans-serif, 'Microsoft JhengHei', 'Apple LiGothic Medium';


html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: 1.6rem;
  color: $black;
}

h1 {
  font-family: 'Oleo Script';
  font-size: 138px;
  text-align: center;
  color: #f4f3f3;
}


// .f1 { font-size: 3rem; }
.f2 { 
  font-size: 4.8rem; 
  @include respond('phone') {
    font-size: 3.6rem; 
  }
}
.f3 { font-size: 3.6rem; }
.f4 { font-size: 2.4rem; }
.f5 { 
  font-size: 1.8rem; 
  @include respond('phone') {
    font-size: 1.6rem; 
  }
}
.f6 { font-size: 1.6rem; }
.f7 { font-size: 1.4rem; }
.font-black {
  font-weight: 900;
}
.font-serif {
  font-family: 'Oleo Script'
}
.primary {
  color: #6dabe1;
}
.secondary {
  color: #e3dbca;
}
.info{
  color: #f2ede1;
}
.white {
  color:  #ffffff;
}

.dark {
  color: rgba(255, 255, 255, 0.5);
}


