.services {
    padding-bottom: 26.8rem;


    @include respond('laptop-xs') {
        padding: 0 16px 12rem;
    }
    .lax {
        z-index: 2;
        position: relative;
    }
    h3 {
        bottom: -18px;
        left: 24px;

        @include respond('laptop-xs') {
            left: 18px;
        }
    }
    .cls-1{
        opacity:0.6;isolation:isolate;
    }
    .cls-2{
        fill:none;
        stroke:#6dabe1;
        stroke-linejoin:round;
    }
    .bg {
        top: 32%;
        left: 7%;

        @include respond('laptop-xs') {
            top: 0;
            left: 0;
        }

        img {
            object-fit: contain;

            @include respond('laptop-xs') {
                object-fit: cover;
            }
        }

    }
    .drone {
        z-index: 2;
        @include respond('laptop-xs') {
            width: 70%;
            z-index: 1;
            min-width: 360px;
        }

    }
    .light {
        top: -100px;

        @include respond('laptop-xs') {
            display: none;
        }
    }

    .line {
        left: 70px;

        @include respond('laptop-xs') {
            display: none;
        }
    }

    .title {
        margin-bottom: 32px;

        svg {
            @include respond('laptop-xs') {
                width: 100%;
                max-width: 500px;
            }
        }
    }

    p {
        max-width: 612px;
        line-height: 2;
        letter-spacing: 0.9px;
    }

    .grid {
        grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
        grid-template-rows: 267px minmax(min-content, max-content);
        align-items: self-start;
        margin-top: 230px;

        @include respond('laptop-xs') {
            margin-top: 80px;
            justify-content: center;
        }

        @include respond('tab-port') {
            margin-top: 40px;
        }
    }

    .tilt {
        width: 100%;
        max-width: 310px;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        .is-overlay {
            width: 100%;
            height: 100%;
            background-color: #000000;
            position: absolute;
            transform-origin: right;
        }
        &:nth-child(2) {
            grid-column: 2 / 3;
            grid-row: 2 / 3;

            @include respond('laptop-xs') {
                grid-row: 1 / 3;
            }
        }

        .absolute {
            left: 16px;
            top: 16px;
        }

        img {
            @include respond('laptop-xs') {
                width: 100%;
            }
        }
    }

    .container {
        width: 100%;
        max-width: 1354px;
        left: 98px;

        @include respond('laptop') {
            left: auto;
        }

        @include respond('laptop-xs') {
            flex-direction: column;
            padding-top: 100px;
            // padding-bottom: 100px;
        }
    }

    .des {
        margin-right: 32px;

        @include respond('laptop-xs') {
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0;
        }
    }

    .grid {
        gap: 44px;

        @include respond('laptop-xs') {
            gap: 30px;
        }
    }
}

.services-show {
    z-index: 1;
    padding-bottom: 17.7rem;
    @include respond('laptop-xs') {
        padding-bottom: 10rem;
    }
    .bg {
        max-width: 664px;
        max-height: 973px;
        top: -100px;
        overflow: hidden;
        img {
            object-fit: contain;
        }
        @include respond('laptop-xs') {
            display: none;
        }
    }
    .container {
        width: 100%;
        max-width: 1166px;
        left: 60px;
        @include respond('laptop-xs') {
            left: auto;
            padding: 0 16px;
        }
    }
    .slider {
        max-width: 1116px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .p1 {
        top: 0;
        left: 0;
        height: 100%;
        img {
            height: 100%;
            width: auto;
        }
    }
    h3 {
        margin-bottom: 12px;
    }
    p {
        max-width: 654px;
        line-height: 2;
        letter-spacing: 0.9px;
    }
    .title {
        margin-left: auto;
        margin-bottom: 30px;
        max-width: 620px;
        span {
            line-height: 1.25;
            // letter-spacing: 3.6px;
            @include letter-spacing(50);
        }
        img {
            bottom: -1px;
            left: 20px;
            @include respond('tab-port') {
                display: none;
            }
        }
        p {
            max-width: 540px;
            line-height: 1.5;
            letter-spacing: 1.2px;
            @include respond('phone') {
                font-size: 1.8rem;
            }
        }
    }
    .lens {
        bottom: -5%;
        right: -12%;
        @include respond('laptop-xs') {
            display: none;
        }
    }
}